<template>
  <div id="nav">
    <!-- <Nav />| -->
  </div>
  <router-view />
</template>

<script>
import Nav from "@/components/Nav.vue";

export default {
  components: {
    Nav,
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: rgb(81, 32, 122);
  background: linear-gradient(
    0deg,
    rgba(81, 32, 122, 1) 20%,
    rgba(163, 158, 166, 1) 70%
  );

  height: 100vh;
}


#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
