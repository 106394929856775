<template>
  <div class="truck_bg">
    <Contact />
    <!-- <Maintenance /> -->
    <footer class="bg-dark py-2"><strong>Website currently under construction</strong></footer>
  </div>
</template>

<script>
import Contact from "@/components/Contact.vue";
// import Maintenance from '@/components/Maintenance.vue'

export default {
  components: {
    Contact,
    // Maintenance
  },
};
</script>

<style scoped>
.truck_bg {
  height: 100vh;
  background-image: url("../assets/truck_trailer.jpg");
  background-size: 100vw;
  background-repeat: no-repeat;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  opacity: .75;
  color: white;
}
</style>
